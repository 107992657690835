<template>
  <b-row>
    <b-col cols="12">
      <table-basic-company-house
        :tableTitle="this.$route.meta.title"
        apiUrl="user"
        apiEndpoint="users"
        buttonTitle="User"
        :perPage="perPage"
        create="true"
        update="true"
        deletes="true"
        search="true"
        show="true"
        :currentPage="currentPage"
        :totalRows="totalRows"
        :fields="fields"
        :items="getItems"
        @changePerPage="changePerPage"
        @changeCurrentPage="changeCurrentPage"
      >
      </table-basic-company-house>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import {
  BRow,
  BCol,
  BAlert,
  VBTooltip,
  BFormGroup,
  BButton,
  BFormInput,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import TableBasicCompanyHouse from "@/views/table/bs-table/TableBasicCompanyHouse.vue";
import axios from "@axios";
import { EventBus } from "@/eventBus.js";

export default {
  name: "List-Admin",
  components: {
    BRow,
    BCol,
    BAlert,
    VBTooltip,
    TableBasicCompanyHouse,
    BFormGroup,
    BButton,
    BFormInput,
    BCard,
    BCardBody,
  },
  data() {
    return {
      response: {
        message: null,
        variant: null,
      },
      config: {
        api: "users",
      },
      perPage: 10,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "profile.firstName", label: "First Name" },
        { key: "profile.lastName", label: "First Last Name" },
        { key: "username", label: "Email" },
        { key: "profile.mobilePhone", label: "Phone" },
        // { key: "status", label: "Status" },
        { key: "action", thClass: "width160px" },
      ],
      items: [],
    };
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    filter() {
      EventBus.$emit("changeValue");
    },
    getItems(ctx, callback) {
      const _ = this;
      let filter = ctx.filter ? "&q=" + ctx.filter : "";
      axios
        .get(
          _.config.api +
            "?limit=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            "&order=createdAt.desc" +
            filter
        )
        .then((resp) => {
          const consume = resp.data;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.count;
          callback(consume.rows || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    renderTable(perPage, currentPage) {
      let _ = this;
      axios
        .get(_.config.api + "?limit=" + perPage + "&page=" + currentPage)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.count;
          _.currentPage = consume.currentPage;
          _.perPage = consume.perPage;
          _.items = consume.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePerPage(perPage) {
      let _ = this;
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>
